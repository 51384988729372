import { ReactElement, useState } from 'react';
import { Grid } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { IStorageFile } from '~/services/api/storageFiles/types';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { isoToFormat } from '~/utils/date';
import FileTypeIcon from './FileTypeIcon';
import { STORAGE_FILE } from '~/ui/constants/paths';
import styles from './Item.module.scss';
import api from '~/services/api';
import downloadFile from '~/utils/downloadFile';
import Loader from '~/ui/components/common/Loader';

interface IProps {
  item: IStorageFile;
}

const Item = ({ item }: IProps): ReactElement => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { onDeleteStorageFile } = useStoreActions(actions => actions.storageFiles);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const handleRemove = async () => {
    try {
      setIsRemoving(true);

      await onDeleteStorageFile(item.id);

      setIsConfirmModalOpen(false);

      showNotify('Document successfully removed');
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  const onDownloadClick = async () => {
    try {
      setIsLoading(true);

      const { data } = await api.storageFiles.getPreSignedUrl(item.id);

      downloadFile(data);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <FileTypeIcon name={item.typeName} color={item.typeColor} />
        </TableCell>
        <TableCell align="center">{isoToFormat(item.createdAt, 'dd MMM y ')}</TableCell>
        <TableCell>{item.documentTypeName}</TableCell>
        <TableCell>
          <Link className={styles.link} to={STORAGE_FILE.replace(':id', String(item.id))}>
            {item.name}
          </Link>
        </TableCell>
        <TableCell>{item.createdByUserFullName}</TableCell>
        <TableCell>
          <Grid container>
            <Grid item>
              {isLoading ? (
                <Loader size={18} />
              ) : (
                <IconButton title="Download" color="primary" onClick={onDownloadClick}>
                  <DownloadIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <IconButton
                title="Remove"
                color="secondary"
                onClick={() => setIsConfirmModalOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      {isConfirmModalOpen && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current document?"
          isLoading={isRemoving}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleRemove}
        />
      )}
    </>
  );
};

export default Item;
