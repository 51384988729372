import { useEffect, useState, ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import api from '~/services/api';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';

interface IParams {
  id: string;
}

const StorageFile = (): ReactElement => {
  const [message, setMessage] = useState(null);

  const { id: idParams } = useParams<IParams>();

  const id = useMemo(() => Number(idParams), [idParams]);

  const onMount = async () => {
    try {
      const { data } = await api.storageFiles.getPreSignedUrl(id);

      window.location.href = data;

      setMessage('The file is downloaded.');
    } catch (e) {
      setMessage(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item>{message ?? <Loader />}</Grid>
    </Grid>
  );
};

export default StorageFile;
