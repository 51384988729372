import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { IStorageFile } from '~/services/api/storageFiles/types';
import styles from './UploadedStorageFiles.module.scss';
import { IIdName } from '~/services/api/types';
import StorageFile from './StorageFile';
import Button from '~/ui/components/common/Button';
import { IFormValues } from './types';
import { getInitialValues, validate } from './form';
import { extractErrorMessage } from '~/utils/error';
import { useStoreActions } from '~/store/hooks';

interface IProps {
  patientId: number;
  storageFiles: IStorageFile[];
  documentTypes: IIdName[];
  onStorageFileRemove: (storageFile: IStorageFile) => void;
  onSubmitted: () => void;
}

const UploadedStorageFiles = ({
  patientId,
  storageFiles,
  documentTypes,
  onStorageFileRemove,
  onSubmitted,
}: IProps): ReactElement => {
  const [isSaving, setIsSaving] = useState(false);

  const { onCreateStorageFilesByPatientId } = useStoreActions(actions => actions.storageFiles);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const formMethods = useForm<IFormValues>({
    resolver: validate,
    defaultValues: getInitialValues(),
  });

  const { control, handleSubmit } = formMethods;

  const { fields, append, remove } = useFieldArray<IFormValues, 'items', ''>({
    control,
    name: 'items',
    keyName: '',
  });

  const documentTypeOptions = useMemo(
    () =>
      documentTypes.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [documentTypes],
  );

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      setIsSaving(true);

      try {
        await onCreateStorageFilesByPatientId({ patientId, data: values.items });

        showNotify('Documents successfully uploaded');

        onSubmitted();
      } catch (e) {
        showError(extractErrorMessage(e));
        setIsSaving(false);
      }
    },
    [onCreateStorageFilesByPatientId, onSubmitted, showError, setIsSaving],
  );

  useEffect(() => {
    fields
      .map((field, index) => ({ field, index }))
      .reverse()
      .forEach(({ field, index }) => {
        if (!storageFiles.some(x => x.id === field.id)) {
          remove(index);
        }
      });

    storageFiles.forEach(storageFile => {
      if (!fields.some(x => x.id === storageFile.id)) {
        append(storageFile);
      }
    });
  }, [storageFiles]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {storageFiles.length > 0 && (
            <Grid item className={styles.uploaded} xs={12}>
              Uploaded
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {storageFiles.map(storageFile => (
                <Grid item xs={12} key={storageFile.id}>
                  <StorageFile
                    storageFile={storageFile}
                    index={fields.findIndex(x => x.id === storageFile.id)}
                    documentTypes={documentTypeOptions}
                    onRemove={() => onStorageFileRemove(storageFile)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" type="submit" color="primary" disabled={isSaving}>
              Upload Files
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default UploadedStorageFiles;
