const downloadFile = (url: string, fileName: string = null): void => {
  const link = document.createElement('a');

  link.href = url;

  if (fileName !== null) {
    link.setAttribute('download', fileName);
  }

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};

export default downloadFile;
